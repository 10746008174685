<template>
  <div class="mapping-settings">
    <v-autocomplete
      :class="{ 'mb-2': errorMsg }"
      :label="
        $localizationService.localize('drp_import.stepper.step.mapping.label')
      "
      :placeholder="$localizationService.localize('common.begin_typing')"
      :items="displayModelMappings"
      :loading="loading"
      :disabled="pLoading"
      v-model="selectedModelMapping"
      ref="inputModelMapping"
      item-text="name"
      item-value="value"
      return-object
      :error-messages="errorMsg"
      clearable
      :filter="filterAutocomplete"
      :search-input.sync="searchModelMapping"
      @change="onSelectModelMapping()"
    >
      <template v-slot:selection="data">
        <ModelMapping
          v-if="data.item != MAPPING_CREATION"
          :pModelMapping="data.item"
          :pLoading="pLoading"
          @onSaveModelMapping="saveModelMapping($event)"
          @onDeleteModelMapping="deleteModelMapping($event)"
          @onDeactivateFocus="$refs.inputModelMapping.isFocused = false"
        />
      </template>
      <template v-slot:no-data>
        <v-list-item-content class="pl-4">
          <ModelMapping @onSaveModelMapping="saveModelMapping($event)" />
        </v-list-item-content>
      </template>
      <template v-slot:item="data">
        <ModelMapping
          v-if="data.item == MAPPING_CREATION"
          @onSaveModelMapping="saveModelMapping($event)"
        />
        <ModelMapping
          v-else
          :pModelMapping="data.item"
          @onSaveModelMapping="saveModelMapping($event)"
          @onDeleteModelMapping="deleteModelMapping($event)"
        />
      </template>
    </v-autocomplete>
    <MappingsSelectionTable
      ref="mappingsTable"
      :pLoading="pLoading"
      :pSelectedModelMapping="selectedModelMapping"
      :pParsedModelItems="parsedModelItems"
      :pReportModelProperties="reportModelProperties"
      @onUpdateMappings="onUpdateMappings($event)"
      @onUpdateSelectedMappingState="emitSelectedModelMappingCompleteState()"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import debounce from "lodash/debounce";
import Vue from "vue";
import {
  DRP_IMPORT_AMAZON_LOGISTICS,
  DRP_IMPORT_ROUTE_STAGING,
} from "../../../utils/utils";

const locService = Vue.prototype.$localizationService;

const DEFAULT_AMAZON_LOGISTIC_MAPPING_NAME = "Amazon Logistics [default]";
const DEFAULT_ROUTE_STAGING_MAPPING_NAME = "Route Staging [default]";
const MAPPING_CREATION = {};

export default {
  name: "MappingSettings",

  props: {
    pLoading: Boolean,
    pImportSession: Object,
    pImportMode: String,
    pStoreModule: String,
  },

  components: {
    ModelMapping: () => import("./ModelMapping.vue"),
    MappingsSelectionTable: () => import("./MappingsSelectionTable.vue"),
  },

  watch: {
    searchModelMapping() {
      if (this.searchModelMapping) {
        this.loadFilteredModelMappings();
      }
    },
  },

  data: () => ({
    MAPPING_CREATION,
    loading: false,
    searchModelMapping: null,

    selectedModelMapping: undefined,
    modelsMapping: [],
    errorMsg: undefined,
  }),

  computed: {
    ...mapState("reportModule", ["reportModels"]),

    displayModelMappings() {
      return [MAPPING_CREATION, ...this.modelsMapping];
    },

    parsedModelItems() {
      return this.pImportSession
        ? JSON.parse(this.pImportSession.rawTableModelJson)
        : [];
    },

    reportModelProperties() {
      if (!this.reportModels) {
        return [];
      }

      let reportModel = {};

      if (this.pImportMode == DRP_IMPORT_AMAZON_LOGISTICS) {
        reportModel = this.getReportModelByParams({
          reportType: "AmazonLogisticsReport",
          reportItemType: "AmazonLogisticsImportReportItem",
        });
      }

      if (this.pImportMode == DRP_IMPORT_ROUTE_STAGING) {
        reportModel = this.getReportModelByParams({
          reportType: "RouteStagingReport",
          reportItemType: "RouteStagingImportReportItem",
        });
      }

      return (reportModel.properties || []).filter(
        (it) => it.name.indexOf("~") == -1,
      );
    },
  },

  methods: {
    getReportModelByParams({ reportType, reportItemType }) {
      const reportItem = this.reportModels.find((it) => it.name == reportType);

      return reportItem
        ? reportItem.itemModels.find((it) => it.name == reportItemType)
        : {};
    },

    clearData() {
      this.selectedModelMapping = undefined;
      this.modelsMapping = [];
      this.errorMsg = undefined;
    },

    async loadAmazonLogisticsReportModelProperties() {
      if (!this.reportModels) {
        await this.$store.dispatch("reportModule/loadModelsIfNeeded");
      }
    },

    changeErrorMsg() {
      const { selectedHeaders } = this.$refs.mappingsTable;

      if (
        (this.parsedModelItems || []).length
        && selectedHeaders.length > (this.parsedModelItems || [[]])[0].length
      ) {
        this.errorMsg = locService.localize(
          "drp_import.stepper.step.mapping.error_msg",
          [selectedHeaders.length],
        );
      } else {
        this.errorMsg = undefined;
      }
    },

    onSelectModelMapping() {
      if (this.selectedModelMapping && this.$refs.mappingsTable) {
        setTimeout(() => {
          this.$refs.mappingsTable.createHeadersByOriginalMappings();
          this.changeErrorMsg();
        });
      }

      if (this.$refs.inputModelMapping) { this.$refs.inputModelMapping.isFocused = false; }

      this.emitSelectedModelMappingCompleteState();
    },

    emitSelectedModelMappingCompleteState() {
      const { headerAutocompleteItems } = this.$refs.mappingsTable || {};
      const tableItems = (this.$refs.mappingsTable || {}).items;

      const allHeadersIsSelected = (headerAutocompleteItems || []).length
        ? (headerAutocompleteItems || []).every((it) => it.disabled)
        : false;

      this.$emit(
        "onUpdateSelectedMappingState",
        this.selectedModelMapping
          && (tableItems || []).length
          && allHeadersIsSelected
          && !this.errorMsg,
      );
    },

    async initiationMapping() {
      await this.$store.dispatch(`${this.pStoreModule}/initiationMapping`, {
        reportId: this.pImportSession.id,
        modelMappingsId: this.selectedModelMapping.id,
      });
    },

    onUpdateMappings(mappings) {
      this.selectedModelMapping.mappings = mappings;

      this.saveModelMapping(this.selectedModelMapping);
    },

    async saveModelMapping(modelMapping) {
      if (modelMapping.id) {
        const updatedModelMapping = await this.$store.dispatch(
          `${this.pStoreModule}/updateModelMapping`,
          modelMapping,
        );

        const mappingInd = this.modelsMapping.findIndex(
          (it) => it.id == modelMapping.id,
        );
        this.modelsMapping.splice(mappingInd, 1, updatedModelMapping);

        if (this.selectedModelMapping.id == modelMapping.id) {
          this.selectedModelMapping = updatedModelMapping;
          this.emitSelectedModelMappingCompleteState();
        }
      } else {
        const newModelMapping = await this.$store.dispatch(
          `${this.pStoreModule}/addNewModelMapping`,
          modelMapping,
        );

        this.modelsMapping.push(newModelMapping);

        this.selectedModelMapping = newModelMapping;
        this.onSelectModelMapping();
      }
    },

    async deleteModelMapping(modelMapping) {
      const index = this.modelsMapping.findIndex(
        (it) => it.id == modelMapping.id,
      );
      this.modelsMapping.splice(index, 1);

      await this.$store.dispatch(
        `${this.pStoreModule}/deleteModelMapping`,
        modelMapping,
      );

      if (
        this.selectedModelMapping
        && this.selectedModelMapping.id == modelMapping.id
      ) {
        this.selectedModelMapping = undefined;
        this.emitSelectedModelMappingCompleteState();
      }
    },

    filterAutocomplete(item, queryText) {
      if (item == MAPPING_CREATION) return item;

      if (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase())
        > -1
      ) { return item; }

      return undefined;
    },

    // TODO: warning
    loadFilteredModelMappings: debounce(function (init) {
      this.loading = true;

      this.$store.dispatch(
        `${this.pStoreModule}/loadFilteredModelMappings`,
        this.searchModelMapping,
      ).then((data) => {
        this.modelsMapping = data;
        this.loading = false;

        if (init) {
          this.selectedModelMapping = this.modelsMapping.find(
            (it) => it.name == DEFAULT_AMAZON_LOGISTIC_MAPPING_NAME
                || it.name == DEFAULT_ROUTE_STAGING_MAPPING_NAME,
          );
          this.onSelectModelMapping();
        }
      });
    }, 300),

    selectDefault() {
      this.loadFilteredModelMappings(true);
    },
  },

  mounted() {
    this.selectDefault();
  },
};
</script>
